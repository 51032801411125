
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
@Component({
  name: 'AgreementDialog',
})
export default class AgreementDialog extends Vue {
    private showDialog: boolean = false; // 弹窗显示与关闭

    // 获取父组件传过来的消息头部
    @Prop(String) private dialogTitle!: string;
    // 获取父组件传过来的消息信息
    @Prop(String) private msg!: string;
    // 获取父组件传过来的确认按钮文字
    @Prop(String) private confirmTitle!: string;
    // 获取父组件传过来的取消按钮文字
    @Prop(String) private closeTitle!: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    private openFlag!: boolean; // 接收父组件传过来的值
    @Watch('openFlag') // 监听父组件传过来的值
    private openFlagFun(value: boolean) {
        this.showDialog = value;
    }
    // 给父组件传值
    @Emit('closeMsg')
    private closeTodo(confirmFlag: boolean): boolean {
        return confirmFlag;
    }
    //  关闭按钮
    private closeClick() {
        this.closeDialog(false);
    }
    //  确定按钮
    private closeDialog(confirmFlag: boolean) {
        this.showDialog = false;
        this.closeTodo(confirmFlag); // 给父组件传值
    }

}
