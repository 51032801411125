
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import SlideVerify from "@/components/SlideVerify.vue";
import { encryptedData } from '@/utils/encrypt';

@Component({
    name: 'CloseDialog',
    components: {
        SlideVerify,
    },
})
export default class CloseDialog extends Vue {
    // 获取父组件传过来的手机号
    @Prop({
        type: String,
        default: '',
    })
    private mobileStr!: string;
    // 获取父组件传过来的短信类型
    @Prop({
        type: String,
        default: '',
    })
    private smsType!: string;
    // 获取父组件传过来的令牌
    @Prop(String) private sessionStr!: string;
    private isPassing: boolean = false;
    private showDialog: boolean = false; // 弹窗显示与关闭
    private imgs: any =  [
        require('@/assets/images/dragVerify/verify-1.png'),
        require('@/assets/images/dragVerify/verify-2.png'),
        require('@/assets/images/dragVerify/verify-3.png'),
        require('@/assets/images/dragVerify/verify-4.png'),
        require('@/assets/images/dragVerify/verify-5.png'),
    ];
    private time: any = null;
    private guid: string = '';
    private screenSize: number = 20;

    @Prop({
        type: Boolean,
        default: false,
    })
    private exitFlag!: boolean; // 接收父组件传过来的值
    @Watch('exitFlag') // 监听父组件传过来的值
    private exitFlagFun(value: boolean) {
        this.showDialog = value;
        if (value) {
            const clientWidth = window.screen.width || document.body.clientWidth;
            if (clientWidth >= 3840) {
                this.screenSize = 40;
            } else if (clientWidth > 1600 && clientWidth < 3840) {
                this.screenSize = 20;
            } else if (clientWidth > 1366 && clientWidth <= 1600) {
                this.screenSize = 18;
            } else if (clientWidth <= 1366) {
                this.screenSize = 16;
            }
        }
        if (value && this.smsType !== '') {
            this.startRequest();
        }
    }
    // 给父组件传值
    @Emit('closeMsg')
    private closeTodo(num: number): number {
        this.showDialog = false;
        return num;
    }

    // 右上角的关闭按钮
    private beforeClose() {
        this.closeVerify();
    }
    private closeVerify() {
        this.closeTodo(0);
        setTimeout(() => {
            const slideBlock: any = this.$refs.slideBlock;
            if (this.$refs.slideBlock !== undefined) {
                slideBlock.reset();
            }
        }, 300);
    }
    private onSuccess(time: any) {
        this.time = (time / 1000).toFixed(1);
        if (this.smsType !== '') {
            this.successRequest();
        } else {
            setTimeout(() => {
                this.closeTodo(1);
            }, 1000);
            setTimeout(() => {
                this.resetImage();
            }, 2000);
        }
    }
    private resetImage() {
        this.time = null;
        const slideBlock: any = this.$refs.slideBlock;
        if (this.$refs.slideBlock !== undefined) {
            slideBlock.reset();
        }
    }
    private onFail() {
      this.$message({
        message: '请重试',
        type: 'warning',
      });
      this.startRequest();
    }
    private onRefresh() {
      this.$message({
        message: '已刷新',
        type: 'success',
      });
      this.startRequest();
    }
    private startRequest() {
        this.guid = this.$uuids4();
        const paramData: any = {};
        paramData.ticket = encryptedData(this.guid);
        paramData.notLoading = true;
        this.$httpService.putData(paramData, `/apiProxy/api/frontend/passport/sms/captcha/ticket`)
        .then((res: any) => {
            // ...
        });
    }
    // 滑动成功后请求
    private successRequest() {
        // 获取登录短信验证码
        const paramData: any = {};
        paramData.captchaUUID = this.guid;
        paramData.mobile = this.mobileStr;
        let urlStr = '';
        if (this.smsType === 'login') {
            urlStr = '/apiProxy/api/frontend/passport/login/sms/captcha';
        } else if (this.smsType === 'register') {
            paramData.session = this.sessionStr;
            paramData.userType = 1;
            urlStr = '/apiProxy/api/frontend/passport/register/sms/captcha';
        } else if (this.smsType === 'forget') {
            urlStr = '/apiProxy/api/frontend/passport/retrieve/sms/captcha';
        }
        if (urlStr === '') {
            this.$message.warning('未知错误~');
            return;
        }
        this.$httpService.postData(paramData, urlStr)
        .then((res: any) => {
            setTimeout(() => {
                this.resetImage();
            }, 200);
            this.closeTodo(1); // 给父组件传值
        }).catch((err: any) => {
            this.closeTodo(0); // 给父组件传值
            setTimeout(() => {
                this.resetImage();
                this.startRequest();
            }, 200);
        });
    }
}
