
import { Component, Vue } from 'vue-property-decorator';
import { useMessage } from '@/utils/message'; // 方便弹出吐司
import { verifyPhone } from '@/utils/utils';
import { ElForm } from 'element-ui/types/form';
import ImageVerify from "@/components/ImageVerify/index.vue";
import AgreementDialog from "@/components/AgreementDialog/index.vue";
import DragVerify from "@/components/DragVerify/index.vue";
import applyList from '@/utils/applyMap';
import HeaderLogin from '@/components/HeaderLogin/index.vue';
import FooterLogin from '@/components/FooterLogin/index.vue';
import { encryptedData } from '@/utils/encrypt';
import { Base64 } from 'js-base64';

@Component({
    name: 'register',
    components: {
        ImageVerify,
        AgreementDialog,
        DragVerify,
        HeaderLogin,
        FooterLogin,
    },
})
export default class Register extends Vue {
    private isWebkit: boolean = true; // 判断是否是webkit内核
    private psw: boolean = false; // 密码
    private confirmPsw: boolean = false; // 确认密码
    // invitation start
    private invitation: string = '';
    private company: string = '';
    // end
    private dragVerifyFlag: boolean = false; // 滑动验证
    private openCodeFlag = false; // 开启图片验证码flag
    private imageCodeSrc = ''; // 图片验证码
    private imageCode: string = ''; // 接收输入的验证码
    private openFlag = false; // 开启协议flag
    private dialogTitle = ''; // 抬头title
    private msg = '';
    private confirmTitle = '同意并继续'; // 确认title
    private closeTitle = '取消'; // 关闭title
    private session = ''; // session
    private guid = ''; // 记录图片验证码 使用的uuid
    private timerData: any = {
        timerFlag: false,
        timerNum: 60,
        msg: '获取短信验证码',
    };
    // 预验证手机号提示
    private icoTipsTab: any = {
        icoTipsFlag: false,
        data: true,
        icoTipsLab: '',
    };
    private registerData: any = {
        userType: 1, // 1，个人；2，企业；新注册用户默认个人用户
        realName: '',
        mobile: '',
        password: '',
        verifyCode: '',
        checked: false,
    };
    private registerRules: any = {
        realName: [
            { required: true, message: '请输入您的姓名', trigger: 'change' },
        ],
        mobile: [
            { required: true, message: '请输入手机号', trigger: 'change' },
            { validator: this.validateMobile, trigger: 'change' },
        ],
        password: [
            { required: true, message: '请输入密码', trigger: 'change' },
            { validator: this.validatePwd, trigger: 'change' },
        ],
        verifyCode: [
            { required: true, message: '请输入验证码', trigger: 'change' },
        ],
        checked: [
            { validator: this.validateChecked, message: '请同意用户注册协议', trigger: 'change' },
        ],
    };
    private mounted() {
        this.$nextTick(() => {
            const userAgent = navigator.userAgent; // 取得浏览器的userAgent字符串
            if (userAgent.indexOf("Opera") > -1) { // 判断是否Opera浏览器
                this.isWebkit = true;
            } else if (userAgent.indexOf("Firefox") > -1) { // 判断是否Firefox浏览器
                this.isWebkit = false;
            } else if (userAgent.indexOf("Chrome") > -1) { // 判断是否Chrome浏览器
                this.isWebkit = true;
            } else if (userAgent.indexOf("Safari") > -1) { // 判断是否Safari浏览器
                this.isWebkit = true;
            } else {
                this.isWebkit = false;
            }
        });
    }
    private validateMobile(rule: any, value: string, callback: any) {
        // 开始验证手机号时，先重置后面显示情况
        this.icoTipsTab = {
            icoTipsFlag: false,
            icoTipsLab: '',
        };
        if (value === '') {
            callback(new Error('请输入手机号'));
        } else if (!verifyPhone(value)) {
            callback(new Error('手机号格式不正确!'));
        } else {
            const paramData: any = {};
            paramData.mobile = this.registerData.mobile;
            paramData.userType = this.registerData.userType;
            paramData.notLoading = true;
            this.$httpService.postData(paramData, this.$apiList.precheck).then((res: any) => {
                this.icoTipsTab = {
                    icoTipsFlag: true,
                    data: true,
                    icoTipsLab: '',
                };
                callback();
            }).catch( (err) => {
                this.icoTipsTab = {
                    icoTipsFlag: true,
                    data: err.success,
                    icoTipsLab: err.message,
                };
            });
        }
    }
    private validatePwd(rule: any, value: string, callback: any) {
        if (value === '') {
            callback(new Error('请输入密码'));
        } else if (value !== '' && (value.length < 6 || value.length > 16)) {
            callback(new Error('密码长度在6~16位'));
        } else {
            callback();
        }
    }
    private validateConfirmPwd(rule: any, value: string, callback: any) {
        if (value === '') {
            callback(new Error('请输入确认密码'));
        } else if (value !== '' && (value.length < 6 || value.length > 16)) {
            callback(new Error('密码长度在6~16位'));
        } else if (value !== this.registerData.password) {
            callback(new Error('两次输入密码不一致!'));
        } else {
            callback();
        }
    }
    private validateChecked(rule: any, value: string, callback: any) {
        if (!value) {
            callback(new Error());
        } else {
            callback();
        }
    }
    private created() {
        if (this.$route.fullPath.indexOf('invitation=') > -1) {
            this.invitation = unescape(this.$route.query.invitation + '');
        }
        if (this.$route.fullPath.indexOf('company=') > -1) {
            this.company = Base64.decode(decodeURI(this.$route.query.company + ''));
        }
        this.getSession();
    }
    private sendCode() {
        if (this.registerData.mobile === '') {
            const registerRef = this.$refs.registerRef! as ElForm;
            registerRef.validateField('mobile');
        } else if (!verifyPhone(this.registerData.mobile)) {
            const registerRef = this.$refs.registerRef! as ElForm;
            registerRef.validateField('mobile');
        } else {
            if (this.timerData.timerFlag) {
                return;
            }
            this.dragVerifyFlag = true;
        }
    }
    private submitForm(formName: any) {
        const elForm = this.$refs[formName]! as ElForm;
        elForm.validate((valid: boolean) => {
            if (valid) {
                this.registerHandle();
            } else {
                return false;
            }
        });
    }
    private openAgreement(applyIndex: number) {
        if (applyIndex === 1) {
            this.dialogTitle = applyList.cloud_title;
            this.msg = applyList.cloud_content;
        } else {
            this.dialogTitle = applyList.community_title;
            this.msg = applyList.community_content;
        }
        this.openFlag = true;
    }
    // 接收关闭弹窗传回来的值
    private propCloseMsg(val: boolean) {
        this.openFlag = false;
        if (!this.registerData.checked) {
            this.registerData.checked = val;
        }
    }
    // 接收关闭弹窗传回来的值
    private propCloseImageCode(val: any) {
        this.openCodeFlag = false;
        if (val[1] !== '') {
            this.startTimer();
        }
    }
    private gotoLogin() {
        if (this.invitation !== '' && this.company !== '') {
            this.$router
            .push(`/login?invitation=${encodeURIComponent(this.invitation)}&company=${encodeURIComponent(Base64.encode(this.company))}`)
            .catch((err) => err);
        } else {
            this.$router.push("/login").catch((err) => err);
        }
    }
    private getSession() {
        this.$httpService.getData({}, this.$apiList.register_session).then((res: any) => {
            this.session = res.session;
        });
    }
    private startTimer() {
        this.timerData.timerFlag = true;
        const myInterval = setInterval( () => {
            this.timerData.timerNum--;
            this.timerData.msg = this.timerData.timerNum + 's后重发';
            if (this.timerData.timerNum <= 0) {
              clearInterval(myInterval);
              this.timerData = {
                  timerFlag: false,
                  timerNum: 60,
                  msg: '获取短信验证码',
              };
            }
        }, 1000);
    }
    private registerHandle() {
        const paramData: any = {};
        paramData.mobile = this.registerData.mobile;
        paramData.userType = this.registerData.userType;
        this.$httpService.postData(paramData, this.$apiList.precheck).then((res: any) => {
            this.startRegister();
        });
    }
    private startRegister() {
        const paramData: any = {};
        paramData.mobile = this.registerData.mobile;
        paramData.password = encryptedData(this.registerData.password);
        paramData.userType = this.registerData.userType;
        paramData.smsCode = this.registerData.verifyCode;
        paramData.realName = this.registerData.realName;
        paramData.inviteCode = this.invitation;
        this.$httpService.postData(paramData, this.$apiList.register).then((res: any) => {
            // 清除各种缓存数据，登录之前先把缓存全部清空一次
            localStorage.clear();
            this.pwdLogin();
        });
    }
    private pwdLogin() {
        const paramData: any = {};
        paramData.username = this.registerData.mobile;
        paramData.password = encryptedData(this.registerData.password);
        paramData.inviteCode = this.invitation;
        this.$httpService.postData(paramData, this.$apiList.login).then((res: any) => {
            useMessage(1, '登录成功');

            // 获取当前用户信息
            this.$httpService.getData({type: 'getUserType'}, this.$apiList.userInfo)
            .then((resCurrent: any) => {
                if (resCurrent.userType === 1) {
                    this.$router.push("/person").catch((err) => err);
                } else {
                    this.$router.push("/company").catch((err) => err);
                }
            });
        });
    }
    private propDragVerifyCloseMsg(num: number) {
        this.dragVerifyFlag = false;
        if (num === 1) {
            this.startTimer();
        }
    }
}
