
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import { useMessage } from '@/utils/message'; // 方便弹出吐司
@Component({
  name: 'ImageVerify',
})
export default class DialogAgreement extends Vue {
    // 获取父组件传过来的验证码图片
    @Prop(String) private imageCodeSrc!: string;
    // 获取父组件传过来的手机号
    @Prop(String) private mobileStr!: string;
    // 获取父组件传过来的短信类型
    @Prop(String) private smsType!: string;
    // 获取父组件传过来的用户类型
    @Prop(String) private userTypeStr!: string;
    // 获取父组件传过来的令牌
    @Prop(String) private sessionStr!: string;
    @Prop({
        type: Boolean,
        default: false,
    })
    private openCodeFlag!: boolean; // 接收父组件传过来的值
    private showDialog: boolean = false; // 弹窗显示与关闭
    private imageCode: string = '';
    private imageStr: string = '';
    private imageSrc: string = '';
    private guid: string = '';
    private smsCode: string = ''; // 记录验证码
    // @Watch('imageCodeSrc', {immediate: true, deep: true}) // 监听父组件传过来的值
    // private imageCodeSrcFun(value: string) {
    //     this.guid = this.$uuids4();
    //     this.imageStr = value;
    //     this.imageSrc = this.imageStr + this.guid;
    // }
    @Watch('openCodeFlag') // 监听父组件传过来的值
    private openCodeFlagFun(value: boolean) {
        this.showDialog = value;
    }
    private created() {
        // this.guid = this.$uuids4();
        // this.imageSrc = this.imageStr + this.guid;
        this.guid = this.$uuids4();
        this.imageStr = this.$apiList.smsCaptcha + `?uuid=`;
        if (this.smsType === 'login') {
            this.imageStr = this.$apiList.smsCaptcha + `?uuid=`;
        } else if (this.smsType === 'register') {
            this.imageStr = this.$apiList.captcha + `?uuid=`;
        } else if (this.smsType === 'forget') {
            this.imageStr = this.$apiList.forgetCaptcha + `?uuid=`;
        }
        if (process.env.NODE_ENV === 'development') {
            this.imageStr = '/apiProxy' + this.imageStr;
        }
        this.imageSrc = this.imageStr + this.guid;
    }
    private resetImage() {
        this.guid = this.$uuids4();
        this.imageSrc = this.imageStr + this.guid;
    }
    // 给父组件传值
    @Emit('closeMsg')
    private closeTodo(confirmFlag: boolean) {
        const imageCodeStorage = this.imageCode;
        this.imageCode = '';
        return confirmFlag ? [this.smsCode, this.guid] : ['', ''];
    }
    //  关闭按钮
    private closeClick() {
        this.showDialog = false;
        setTimeout(() => {
            this.resetImage();
        }, 200);
        this.closeTodo(false);
    }
    //  确定按钮
    private closeDialog(confirmFlag: boolean) {
        if (confirmFlag && this.imageCode === '') {
          useMessage(2, '请输入验证码~');
          return;
        }
        // 获取登录短信验证码
        const paramData: any = {};
        paramData.captcha = this.imageCode;
        paramData.captchaUUID = this.guid;
        paramData.mobile = this.mobileStr;
        let urlStr = '';
        if (this.smsType === 'login') {
            urlStr = '/apiProxy/api/frontend/passport/login/sms/captcha';
        } else if (this.smsType === 'register') {
            paramData.session = this.sessionStr;
            paramData.userType = this.userTypeStr;
            urlStr = '/apiProxy/api/frontend/passport/register/sms/captcha';
        } else if (this.smsType === 'forget') {
            paramData.userType = this.userTypeStr;
            urlStr = '/apiProxy/api/frontend/passport/retrieve/sms/captcha';
        }
        if (urlStr === '') {
            useMessage(2, '未知错误~');
            return;
        }
        this.$httpService.postData(paramData, urlStr)
        .then((res: any) => {
            this.smsCode = res.smsCode || '';
            this.showDialog = false;
            setTimeout(() => {
                this.resetImage();
            }, 200);
            this.closeTodo(confirmFlag); // 给父组件传值
        }).catch((err: any) => {
            this.resetImage();
        });
    }

}
