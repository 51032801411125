const applyList = {
    cloud_title: '摩尔云用户协议',
    cloud_content: `
<h1>摩尔云平台服务协议</h1>
<h1>欢迎您使用摩尔云工业互联网平台服务！</h1>
<h1>为使用摩尔云平台服务，您应当阅读并遵守《摩尔云平台服务协议》（以下简称“本协议“）等相关协议、规则。请您务必审慎阅读、充分理解各条款内容，特别是免除或限制责任的相应条款，以及开通或使用某项服务的单独协议，并选择接受或不接受。</h1>
<h1>一．重要提示</h1>
<h1>1. 本协议是您（以下简称“您”或“用户”）与摩尔云平台(网址：www.morewiscloud.com)的经营者摩尔元数（厦门）科技有限公司（以下简称“摩尔云”）之间关于您注册并使用摩尔云平台以获得相应服务（以下简称“摩尔云平台服务”）所订立的对双方有约束力的协议。</h1>
<h1>2. 摩尔云平台已经发布的或未来可能发布的各项服务对应的服务协议、使用规则、结算规则、法律声明等，均构成本协议的组成部分，与本协议具有同等法律效力。如果本协议某一（些）条款被认定为无效，而其他条款仍能保持有效且其执行不受影响，摩尔云可决定是否继续履行该等其他条款。</h1>
<h1>3. 请您认真阅读本协议并充分理解本协议各条款的内容。如果您对本协议的条款有疑问的，请通过摩尔云网页所载的联系方式400-887-4949进行咨询，摩尔云将向您解释条款内容。一旦您按照注册页面提示填写信息、提交文件、阅读并同意本协议且完成注册后，即表示您已经充分阅读、理解并接受本协议的全部内容。如果您不同意接受本协议或其任何条款的约定或无法准确理解摩尔云对本协议条款的解释，您应当立即终止注册程序及在摩尔云平台上的任何操作。</h1>
<h1>4. 您理解并同意，摩尔云平台有权根据法律法规的变化及经营需要，不时对本协议条款进行修改并公布在摩尔云平台，修改后的条款一经公布立即生效，摩尔云无需另行通知您。您应当主动不时查阅摩尔云平台公布的本协议条款。若您继续使用摩尔云平台服务，则视为接受摩尔云对本协议的修改。</h1>
<h1>5. 您确认，您是具备完全民事权利能力和完全民事行为能力的自然人、法人或其他组织（以下简称“法律主体”）。如果您不具备前述法律主体资格，请立即终止注册程序及在摩尔云平台上的任何操作，且摩尔云有权注销您的账号。摩尔云因此受到损失的，将向您和/或您的监护人索赔。</h1>
<h1>二． 摩尔云平台账号信息</h1>
<h1>1. 账号注册</h1>
<p>在您按照摩尔云平台页面提示信息填写完整注册信息、完成《摩尔云平台服务协议》的阅读、并完成账号激活操作等全部摩尔云平台注册程序后，您即获得了摩尔云平台账号并正式成为摩尔云的用户。</p>
<p>您应当保证您在注册过程中填写的信息准确、真实和完整。如果您填写的信息（包括但不限于企业名称、联系人、电话、电子邮件、联系地址等）有任何变更，您应当及时登录摩尔云平台进行更新。如果摩尔云发现您填写的注册信息存在错误、不真实或不完整的情况，或通过您填写的联系方式信息无法联系到您，摩尔云有权要求您立即进行改正、更新相关信息。</p>
<h1>2. 实名认证</h1>
<p>您理解并同意，即使您获得了摩尔云平台账号亦不代表您已开通了摩尔云平台的所有服务。为保障账号及交易安全，摩尔云可能会随时要求您提交更多的身份资料和信息以完成账号的实名认证，只有完成账号的实名验证，您才可以使用相应的摩尔云平台服务。并且您需授权摩尔云可以通过第三方查询和审核您的身份和资料。</p>
<h1>3. 账号安全</h1>
<p>您应当妥善保管您的摩尔云平台账号和密码，您通过摩尔云平台账号执行的一切操作都不可撤回并将被视为是您身份及行为的唯一识别依据，您须对在该摩尔云平台账号下发生的所有活动承担责任。请您注意保护摩尔云平台账号及密码安全，并在使用完毕摩尔云平台服务后安全退出登录。除非依照法律规定或者经过摩尔云的同意，您不得以任何方式向任何第三方泄露、转让或赠与您的摩尔云平台账号，否则摩尔云有权随时中止或终止您的账号使用，甚至注销您的账号。一旦您发现任何人未经您的授权使用您的摩尔云平台账号和密码，您应当立即通知摩尔云，摩尔云会在合理时间内采取适当措施，但对于在摩尔云采取措施之前发生的损失，摩尔云不承担责任。</p>
<h1>4. 账号查询、冻结及注销</h1>
<p>您理解并同意，摩尔云根据合理判断，有权在下列情形下自行对您的账号进行查询（包括但不限于查询注册信息、交易信息、资金信息等）、冻结和/或注销：</p>
<p>1） 国家司法、行政、安全机关等权利机构要求的；</p>
<p>2） 您通过摩尔云平台账号的操作和交易存在违反本协议约定、法律法规和/或侵犯第三人合法权益的情形的；</p>
<p>3） 摩尔云基于单方面的合理判断，认为您的账号操作、交易记录等存在异常的；</p>
<p>4） 您的账号遭到其他用户投诉，且投诉用户提供一定证据予以证明的；</p>
<p>5） 您连续12个自然月未使用您的账号登录摩尔云平台，且您的账号中不存在未到期的摩尔云平台服务的；</p>
<p>6） 您实施了影响或侵害摩尔云和/或摩尔云关联伙伴的合法权益的行为，包括但不限于：</p>
<p style="\&quot;margin-left:2em;\&quot;">a. 违反摩尔云和/或摩尔云关联伙伴的服务协议、条款及规范等内容；</p>
<p style="\&quot;margin-left:2em;\&quot;">b. 破坏或试图破坏摩尔云和/或摩尔云关联伙伴公平交易环境或正常交易秩序；</p>
<p style="\&quot;margin-left:2em;\&quot;">c. 任何使用含有摩尔云和/或摩尔云关联伙伴的名称、品牌且有误导性的表述或标识，明示或暗示与用于摩尔云和/或摩尔云关联伙伴具有某种关系的；</p>
<p style="\&quot;margin-left:2em;\&quot;">d. 摩尔云认为可能是与上述行为性质相同或存在上述类似风险的其他情形。</p>
<p>7） 您所提供的资料信息与事实不符或所提供的资料信息已变更而未及时更新或有任何误导之嫌</p>
<p>8）用户理解并同意，即便在本协议终止及用户的服务被终止后，摩尔云仍有权：</p>
<p>a.继续保存并使用您的用户信息；</p>
<p>b.继续向用户主张在其使用摩尔云服务期间因违反法律法规、本协议及平台规则而应承担的责任。</p>
<h1>三． 摩尔云平台使用规则</h1>
<p>1.     您在使用摩尔云平台的过程中，应当遵守国家、地方法律法规、行业惯例和社会公共道德。不得利用摩尔云平台进行储存、发布、传播以下信息和内容：</p>
<p>1） 违反国家法律法规政策的任何信息；</p>
<p>2） 违反国家规定的政治宣传和/或新闻信息；</p>
<p>3） 涉及国家秘密和/或安全的信息；</p>
<p>4） 封建迷信和/或淫秽、色情、下流的信息或教唆犯罪的信息；</p>
<p>5） 博彩有奖、赌博游戏；</p>
<p>6） 违反国家民族和宗教政策的信息；</p>
<p>7） 妨碍互联网运行安全的信息；</p>
<p>8） 侵害他人合法权益的信息和/或其他有损于社会秩序、社会治安、公共道德的信息。</p>
<p>此外，您不得为他人发布和传播上述不符合国家规定和/或本协议约定的信息提供任何便利，包括但不限于设置URL、BANNER链接等。</p>
<p>2.    您保证不会利用技术或其他手段破坏或扰乱摩尔云平台秩序及网络安全。</p>
<p>3.     您享有言论自由权利，并拥有适度修改、删除自己发表的文章、评价的权利。</p>
<p>4.     如果您使用的某项服务中包含可下载的摩尔云软件，则摩尔云仅授予您非独占性的、不可转让的、非商业性的使用许可。您不得复制、修改、发布、出售或出租服务或所含软件的任何部分，也不得进行反向工程或试图提取该软件的源代码。</p>
<p>5.     您应当尊重摩尔云及其他第三方的知识产权和其他合法权利，并保证在发生侵犯上述权益的违法事件时尽力保护摩尔云及其股东、雇员、合作伙伴等免于因该等事件受到影响或损失。</p>
<p>6.     摩尔云可能会以电子邮件、手机短信、电话等方式通知您服务进展情况以及提示您进行相关操作，但摩尔云不保证您能够收到或者及时收到该邮件、手机短信或电话等。因此，在服务过程中您应当及时登录到摩尔云平台查看和进行相关操作。因您没有及时查看和进行相关操作而导致纠纷或损失的，摩尔云不承担责任。</p>
<p>7.     摩尔云APP开发者需保证上传的APP软件无版权问题；所上架的APP需经摩尔方审核，并且最终解释权归属摩尔所有；同时开发者需对自己上传的APP进行售后维保工作，按摩尔云要求上传客服人员、与技术支持人员信息。APP产生交易，摩尔云开发者的提现规则需按照摩尔云制定的结算规则。</p>
<p>8.     未经摩尔云同意，禁止用户在摩尔云平台上发布任何形式的广告。</p>
<p>9.     如果您通过摩尔云平台使用的某项服务来自第三方的任何产品或服务，您还可能受限于该等第三方的相关条款和条件，摩尔云对此不予过问亦不承担任何责任，本协议不影响您与该第三方的法律关系。</p>
<h1>四． 摩尔云平台隐私政策</h1>
<h1>1. 信息的收集范围。</h1>
<p>您同意并授权摩尔云收集您的以下信息：</p>
<p>1） 身份识别信息，包括但不限于您的姓名、名称、身份证明、资质证明、指定联系人、所在省份/城市、联系地址、邮政编码、电话号码、电子邮件等；</p>
<p>2） 信用信息，包括但不限于关于您的任何信用状况、信用分、信用报告信息；</p>
<p>3） 摩尔云平台操作信息，包括但不限于您的IP地址、操作系统版本信息、登录记录、指令信息等；</p>
<p>4） 摩尔云平台交易信息，包括但不限于与第三方达成的交易信息（包括但不限于交易方、达成交易的内容、时间、金额）及支付信息等（包括但不限于支付时间、支付金额、支付工具、银行账户及支付账户信息）；</p>
<p>5） 其他根据摩尔云平台服务的需要而收集的您的信息，包括但不限于您对摩尔云平台服务的意见、建议、您曾经使用或经常使用的服务、功能、使用场景和使用习惯等信息。</p>
<h1>2. 信息的收集方法</h1>
<p>您同意并授权摩尔云通过以下方法收集您的信息：</p>
<p>1） 摩尔云将收集和储存在您注册、登录、浏览摩尔云平台及/或使用摩尔云平台服务时主动向摩尔云提供的信息；</p>
<p>2） 摩尔云将收集和储存摩尔云在向您提供摩尔云平台服务的过程中记录的与您有关的信息；</p>
<p>3） 摩尔云将收集和储存您通过摩尔云的客服人员及/或其他渠道主动提交或反馈的信息；</p>
<p>4） 摩尔云向关联公司、商业合作伙伴及第三方独立资料来源，收集和储存其合法获得的与您有关的信息；</p>
<p>5）Cookies相关</p>
<p>通过摩尔云所设Cookie所取得的有关信息，将适用本规则；</p>
<p>在摩尔云平台上发布信息的第三方通过其所发布信息在用户计算机上设定Cookies的，将遵照该第三方的隐私权政策；</p>
<p>编辑和删除个人信息的权限：用户可以对用户的个人信息进行编辑和删除，除非摩尔云另有规定。</p>
<h1>3. 信息的用途</h1>
<p>您同意并授权摩尔云出于以下用途使用您的信息：</p>
<p>1） 向您提供摩尔云平台的服务，并进行摩尔云平台的管理和优化；</p>
<p>2） 提升和改善摩尔云平台现有服务的功能和质量，包括但不限于服务内容的个性化定制及更新；</p>
<p>3） 开展摩尔云平台服务相关的市场活动，向您推送最新的市场活动信息及优惠方案；</p>
<p>4） 提高摩尔云平台服务的安全性，包括但不限于身份验证、客户服务、安全防范、诈骗监测、存档和备份；</p>
<p>5） 协助国家司法、行政、安全机关等权利机构开展调查，并遵守适用法律法规及其他向有权机构承诺之义务；</p>
<p>6） 摩尔云可能向您发送与上述用途有关的信息和通知，包括但不限于为保证服务完成所必须的验证码、使用服务时所必要的推送通知、服务费用优惠及减免信息、关于摩尔云平台服务的新闻以及市场活动及优惠促销信息。</p>
<h1>4. 信息的共享</h1>
<p>摩尔云对您的信息承担保密义务，但您授权我们在下列情况下将您的信息与第三方共享：</p>
<p>1） 为了提升摩尔云平台服务质量或向您提供全新的产品及服务，摩尔云会在关联公司内部共享您的相关信息，也可能将摩尔云收集的信息提供给第三方用于分析和统计；</p>
<p>2） 如果您通过摩尔云平台使用的某些服务是由摩尔云平台的供应商、合作伙伴或入驻摩尔云平台的第三方服务商提供的，摩尔云将与其共享向您提供相应产品及服务所必需的信息；</p>
<p>3） 为了与第三方共同开展市场推广活动，摩尔云可能与其共享开展活动所必需的以及在活动过程中产生的您的相关信息；</p>
<p>4） 为了维护您的合法权益，在协助处理与您有关的交易纠纷或争议时，摩尔云会向您的交易相对方或存在利害关系的第三方提供解决交易纠纷或争议所必需的信息；</p>
<p>5） 根据法律法规的规定及商业惯例，摩尔云需要接受第三方的审计或尽职调查时，可能向其提供您的相关信息；</p>
<p>6） 根据法律法规的规定或行政机关、司法机构等有权机关要求，摩尔云会向其提供您的相关信息；</p>
<p>7） 其他经您同意或授权可以向第三方提供您的个人信息的情况。</p>
<h1>5. 信息的安全保护措施</h1>
<p>摩尔云及摩尔云的关联公司将采用严格的安全制度以及行业通行的安全技术和程序来确保您的信息不被丢失、泄露、毁损或滥用。摩尔云的供应商、合作伙伴或入驻摩尔云平台的第三方服务商将受到保密协议的约束，同时还将受到数据信息的权限控制和操作监控。但是，请您注意，摩尔云无法保证前述第三方一定会按照我们的要求采取保密措施，我们亦不对第三方的行为及后果承担任何责任。此外，任何安全系统都存在可能的及未知的风险。作为用户，您可根据您的意愿决定是否使用摩尔云平台的服务，是否主动提供您的信息。</p>
<h1>6. 信息的查询和更改</h1>
<p>您可以查看您提供给摩尔云的注册信息及使用摩尔云平台过程中产生的其他信息。如果您希望删除或更正您的信息，请通过摩尔云相关网页所载的联系方式与摩尔云联系。</p>
<h1>五． 不可抗力</h1>
<p>遭受不可抗力的一方可中止履行本协议项下的义务直至不可抗力事件的影响消除为止，并且无需承担违约责任，但应尽合理努力克服该事件、减少损失的扩大。不可抗力指该方不能控制、不可预见或即使预见亦无法避免的事件，该等不可抗力事件足以妨碍、影响或延误任何一方根据本协议履行其全部或部分义务。该事件包括但不限于自然灾害、战争、政策变化造成的事件。</p>
<h1>六． 责任限制</h1>
<p>1.     您理解并同意，您在使用摩尔云平台服务及相关网络服务过程中存在的风险将完全由您本人承担，摩尔云在本协议约定的责任范围以外不承担任何责任。</p>
<p>2.     在法律允许的情况下，摩尔云对于与本协议有关的任何间接的、惩罚性的、特殊的、派生的损失（包括但不限于业务损失、收益损失、利润损失、使用数据、商誉或其他经济利益的损失），不承担任何责任，即使事先已被告知此等损失的可能性。</p>
<p>3.     您理解并确认，对于摩尔云向您提供的下列服务本身及其引发的任何损失，摩尔云无需承担任何责任：</p>
<p>1） 摩尔云向您免费提供的各项网络服务；</p>
<p>2） 摩尔云向您赠送或附赠的免费体验的任何产品或服务。</p>
<p>4.     理解并确认，摩尔云平台可能因下列情况无法正常提供服务，摩尔云就此不承担损害赔偿责任：</p>
<p>1） 摩尔云在系统停机维护期间；</p>
<p>2） 电信设备或IaaS服务资源出现故障不能进行数据传输；</p>
<p>3） 由于黑客攻击、电信部门技术调整或故障、网站升级、银行方面的问题等原因而造成的服务中断或者延迟。</p>
<p>5.     您理解并同意，因您使用摩尔云平台服务、违反本协议或在您的账号下采取的任何行动，而导致的任何第三方索赔，应且仅应由您本人承担。如果由此引摩尔云及其关联公司、员工、客户、供应商和合作伙伴被第三方索赔的，您应承担全部责任。</p>
<p>6.     您通过摩尔云平台服务访问和使用的摩尔云合作伙伴或入驻摩尔云平台的第三方服务商的服务质量仅为该第三方的责任，对于该第三方的服务，摩尔云在任何情况下都不会承担责任。</p>
<h1>七． 摩尔云的权利和义务</h1>
<p>1）摩尔云有义务在现有技术的基础上维护整个网站的正常运行，并努力提升和改进技术，使用户的活动得以顺利进行。</p>
<p>2）用户理解并认同，因网上社区平台的特殊性，摩尔云没有义务对所有用户使用摩尔云的过程及行为进行事前及实时审查。但用户在摩尔云上有下列行为的，将授权摩尔云可以普通、非专业人员的知识水平标准或根据自己掌握的信息进行独立判断，并有权做出相应处理，而无须事先通知用户或取得用户同意：</p>
<p style="\&quot;margin-left:2em;\&quot;">① 摩尔云有权对用户的注册信息进行查阅，如发现注册信息中存在任何问题的，有权向用户发出询问及要求改正或者做出冻结或关闭账户等处理；</p>
<p style="\&quot;margin-left:2em;\&quot;">② 用户违反本协议或摩尔云任何规则或有违反法律法规和地方规章的行为的，摩尔云有权删除其信息，禁止用户发言，限制用户订购，注销用户账户并按照相关法律规定向相关国家主管部门进行披露；</p>
<p style="\&quot;margin-left:2em;\&quot;">③ 如用户在摩尔云进行下列行为，摩尔云有权对用户采取删除其信息、禁止用户发言、冻结或注销用户账户等限制性措施：包括：</p>
<p>a) 发布或以电子邮件或以其他方式传输存在恶意、虚假和侵犯他人人身财产权利内容的信息；</p>
<p>b) 将有关干扰、破坏或限制任何计算机软件、硬件或通讯设备功能的软件病毒或其他计算机代码、档案和程序之资料，加以上载、发布、发送电子邮件或以其他方式传送；</p>
<p>c) 干扰或破坏摩尔云和服务或与摩尔云和服务相连的服务器和网络；</p>
<p>d) 发布其他违反公共利益或可能严重损害摩尔云和其他用户合法利益的信息。</p>
<p>3）用户理解并同意，当用户涉嫌侵犯他人合法权益， 摩尔云有权在初步判断涉嫌侵权行为可能存在的情况下，在必要时可向权利人提供必要的已掌握的涉及用户的相关信息。</p>
<p>4）用户理解并同意，当国家行政、司法机关主动依职权或根据他人的投诉，调查用户在摩尔云上的相关行为时，摩尔云有义务予以配合，并将所掌握的信息提供给上述国家机关。</p>
<p>5）用户在此授予摩尔云无偿、永久的独家使用权，并有权对该使用权进行再授权，以使摩尔云有权在全球范围内全部或部分地使用、复制、修改、改写、发布、翻译和展示用户登记或发布在摩尔云上的各类信息，或制作其派生作品，和/或以现在已知或日后开发的任何形式、媒体或技术，将上述信息纳入摩尔云其他作品内。</p>
<h1>八． 软件形式</h1>
<p>若摩尔云依托“软件”向您提供服务，您还应遵守以下约定：</p>
<p>1）摩尔云可能为不同的终端设备开发不同的软件版本，您应当根据实际需要选择下载合适的版本进行安装。</p>
<p>2）如果您从未经合法授权的第三方获取本软件或与本软件名称相同的安装程序，摩尔云将无法保证该软件能否正常使用，并对因此给您造成的任何损失不予负责。</p>
<p>3）为了增进用户体验、完善服务内容，摩尔云将不时提供软件更新服务(该更新可能会采取软件替换、修改、功能强化、版本升级等形式)。为了改善用户体验，保证服务的安全性和功能的一致性，摩尔云有权对软件进行更新或者对软件的部分功能效果进行改变或限制。</p>
<p>4）软件新版本发布后，旧版软件可能无法使用。摩尔云不保证旧版软件继续可用，及相应的客户服务，请您随时核对并下载最新版本。</p>
<p>5） 除非法律允许或摩尔云书面许可，您不得从事下列行为：</p>
<p style="\&quot;margin-left:2em;\&quot;">① 删除软件及其副本上关于著作权的信息；</p>
<p style="\&quot;margin-left:2em;\&quot;">② 对软件进行反向工程、反向汇编、反向编译或者以其他方式尝试发现软件的源代码；</p>
<p style="\&quot;margin-left:2em;\&quot;">③ 对摩尔云拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；</p>
<p style="\&quot;margin-left:2em;\&quot;">④ 对软件或者软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经合法授权的第三方工具/服务接入软件和相关系统；</p>
<p style="\&quot;margin-left:2em;\&quot;">⑤ 修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论上述行为是否为商业目的；</p>
<h1>九． 适用法律与争议解决</h1>
<p>本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国法律。</p>
<p>若您、摩尔云或第三方之间发生任何纠纷或争议，各方首先应友好协商解决；协商不成的，三方均同意将纠纷或争议提交摩尔元数（厦门）科技有限公司所在地人民法院管辖。</p>`,
    community_title: '研习社用户协议',
    community_content: `
    <p>感谢您成为研习社的注册用户。</p>
    <p>《研习社用户服务协议》（以下简称“本协议”）是由研习社的用户（以下简称“用户或您”）与研习社的运营方之间，就网站平台服务等相关事宜共同缔结。本协议具有合同效力，您应当阅读并遵守本协议。请您务必审慎阅读、充分理解各条款内容。 </p>
    <h3>一、 协议确认</h3>
        <p>1、您同意并点击确认本协议条款并完成注册程序后，才能成为研习社的正式注册用户，并享受各类服务。</p>
        <p>2、根据国家法律法规变化及网站运营需要，研习社有权对本协议条款不时进行修改，修改后的协议一经发布并经您确认同意后即刻生效，并代替原来的协议。您可在研习社随时查阅最新协议。您若不同意更新后的协议，则将不能享受研习社依据本协议提供的各项服务。</p>
        <p>3、所有规则为本协议不可分割的组成部分，与本协议正文具有同等法律效力。在您申请注册成为研习社注册会员前，请仔细阅读本协议的正文内容及以上各类规则。</p>
        <p>4、在您完成用户注册程序后，即表示您自愿接受本协议，正式成为研习社的注册用户，并认可本协议全部条款内容。若您不同意本协议，或对本协议中的条款存在任何疑问，请您立即停止研习社用户注册程序，并可以选择不使用本网站服务。 </p>
        <p>5、您点击同意本协议后，即视为您确认自己具有享受研习社服务相应的权利能力和行为能力，能够独立承担法律责任。</p>
        <p>6、研习社保留在法律允许的范围内独自决定拒绝服务、关闭用户账户、清除或编辑内容的权利。 </p>
    <h3>二、 账户管理</h3>
        <p>1、您注册成功后，研习社会为您开通一个账户，作为您使用研习社服务的唯一身份标识。该账户的所有权归属于研习社，您仅拥有使用权。登录账户时，您可以使用您提供或确认的用户名、邮箱、手机号码或研习社允许的其他方式作为注册用户名进行登录，但在登录时您必须输入您设定并保管的账户密码。为保护您的权益，您在设定账户的密码时，请勿使用重复性或者连续数字的简单密码。请您对密码加以妥善保管，切勿将密码告知他人，因密码保管不善而造成的所有损失由您自行承担。</p>
        <p>2、您可以为您的账户设定昵称，但该昵称不得侵犯他人合法权益。如研习社认为您设定的昵称涉嫌侵犯他人合法权益的，研习社有权终止为您提供服务，并注销您的昵称。若您不再是研习社的注册用户，对于您的原注册账户的昵称，研习社可以将其开放给任意注册用户。</p>
        <p>3、您对通过您的账户所进行的活动、行为和事件依法享有权利和承担责任，且不得以任何形式转让账户、授权他人使用您的账户以及与他人交易账户。</p>
        <p>4、鉴于网络服务的特殊性，研习社无义务审核是否是您本人使用该组用户名及密码，仅审核用户名及密码是否与数据库中保存的一致，任何人只要输入的用户名及密码与数据库中保存的一致，即可凭借该组用户名及密码登陆并获得研习社所提供的各类服务，所以即使您认为您的账户登陆行为并非您本人所为，研习社将不承担因此而产生的任何责任。 </p>
        <p>5、若您在研习社成功注册账户后，连续12个月时间内未使用该账户，则研习社有权对您的账户进行清理。 </p>
        <p>6、用户向研习社提出注销账户申请，经研习社审核同意后，由研习社注销该用户账户，本协议即告终止。但在注销该用户账户后，研习社仍有权：</p>
            <p style="\&quot;margin-left:2em;\&quot;">① 保留该用户的注册信息及过往的全部行为记录；</p>
            <p style="\&quot;margin-left:2em;\&quot;">② 如用户在账户注销前在研习社上存在违法行为或违反本协议的行为，研习社仍可行使本协议所规定的权利。</p>
        <p>7、在下列情况下，研习社可以通过注销用户账户的方式单方解除本协议：</p>
            <p style="\&quot;margin-left:2em;\&quot;">① 在用户违反本协议相关规定时，研习社有权暂停或终止向该用户提供服务。如该用户在研习社暂停或终止提供服务后，再一次直接或间接或以他人名义注册为研习社用户的，则研习社有权再次暂停或终止向该用户提供服务；</p>
            <p style="\&quot;margin-left:2em;\&quot;">② 一经发现用户注册信息中的内容是虚假的，研习社即有权终止向该用户提供服务；</p>
            <p style="\&quot;margin-left:2em;\&quot;">③ 本协议修改或更新时，如用户表示不愿接受新的服务协议的，研习社有权终止向该用户提供服务；</p>
            <p style="\&quot;margin-left:2em;\&quot;">④ 研习社认为需终止提供服务的其他情况。</p>
        <p>8、用户理解并同意，即便在本协议终止及用户的服务被终止后，研习社仍有权：</p>
            <p style="\&quot;margin-left:2em;\&quot;">① 继续保存并使用您的用户信息；</p>
            <p style="\&quot;margin-left:2em;\&quot;">② 继续向用户主张在其使用研习社服务期间因违反法律法规、本协议及平台规则而应承担的责任。</p>
    <h3>三、 用户权利与义务</h3>
        <p>您有权根据本协议的约定，以及研习社上发布的相关规则在研习社（含研习社移动客户端及其他登陆客户端）上查询各类信息、发表文章、参与讨论、邀请关注好友、上传图片、参加研习社的有关活动，以及使用研习社提供的其他服务。</p>
        <p>1、 您保证在研习社活动中遵守法律法规、不违背社会公德及遵守诚实信用原则，不扰乱研习社的正常秩序，因此您将不会实施以下任一行为。</p>
            <p style="\&quot;margin-left:2em;\&quot;">① 以任何机器人软件、蜘蛛软件、爬虫软件、刷屏软件等非研习社认可的方式访问或登录研习社；</p>
            <p style="\&quot;margin-left:2em;\&quot;">② 通过任何方式对研习社造成或可能造成不合理或不合比例的重大负荷；</p>
            <p style="\&quot;margin-left:2em;\&quot;">③ 通过发送病毒、木马等行为攻击其它用户的账号或终端设备；</p>
            <p style="\&quot;margin-left:2em;\&quot;">④ 冒充研习社工作人员欺骗其他用户；</p>
            <p style="\&quot;margin-left:2em;\&quot;">⑤ 通过留言、评价等手段发送违规或垃圾信息；</p>
            <p style="\&quot;margin-left:2em;\&quot;">⑥ 协助他人获取不正当利益；教唆他人从事本协议所禁止的行为；</p>
            <p style="\&quot;margin-left:2em;\&quot;">⑦ 研习社其他规则规定的不当行为。</p>
        <p>2、 您享有言论自由权利，并拥有适度修改、删除自己发表的文章、评价的权利。</p>
        <p>3、您理解并保证不在研习社传输或发表包含以下内容的言论：</p>
            <p style="\&quot;margin-left:2em;\&quot;">① 反对宪法所确定的基本原则，煽动、抗拒、破坏宪法和法律、行政法规实施的；</p>
            <p style="\&quot;margin-left:2em;\&quot;">② 煽动颠覆国家政权，推翻社会主义制度，煽动、分裂国家，破坏国家统一的；</p>
            <p style="\&quot;margin-left:2em;\&quot;">③ 损害国家机关信誉的、损害国家荣誉和利益的、损害社会公共利益和涉及国家安全的；</p>
            <p style="\&quot;margin-left:2em;\&quot;">④ 煽动民族仇恨、民族歧视，破坏民族团结的；</p>
            <p style="\&quot;margin-left:2em;\&quot;">⑤ 任何包含对种族、性别、宗教、地域内容等歧视的；</p>
            <p style="\&quot;margin-left:2em;\&quot;">⑥ 捏造或者歪曲事实，散布谣言，扰乱社会秩序的；</p>
            <p style="\&quot;margin-left:2em;\&quot;">⑦ 宣扬封建迷信、邪教、淫秽、色情、赌博、暴力、凶杀、恐怖、教唆犯罪的；</p>
            <p style="\&quot;margin-left:2em;\&quot;">⑧ 骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的，淫秽的、不文明的、公然侮辱他人或者捏造事实诽谤他人的，或者进行其他恶意攻击的；</p>
            <p style="\&quot;margin-left:2em;\&quot;">⑨ 其他违反宪法和法律行政法规的。</p>
        <p>4、用户在发表文章或图片等内容时，除遵守本条款外，还应遵守研习社的其他相关规定。</p>
        <p>5、未经研习社同意，禁止用户在研习社上发布任何形式的广告。</p>
        <p>6、本协议依据国家相关法律法规规章制定，您同意严格遵守以下义务：</p>
            <p style="\&quot;margin-left:2em;\&quot;">① 从中国大陆向境外传输资料信息时必须符合中国有关法律法规；</p>
            <p style="\&quot;margin-left:2em;\&quot;">② 不得利用研习社从事洗钱、窃取商业秘密、窃取个人信息等违法犯罪活动；</p>
            <p style="\&quot;margin-left:2em;\&quot;">③ 不得干扰研习社的正常运营秩序，不得侵入研习社及国家计算机信息系统；</p>
        <p>7、 您不得利用任何非法手段获取其他用户个人信息，不得将其他用户信息用于任何营利或非营利目的，不得泄露其他用户或权利人的个人隐私，否则研习社有权采取措施制止您的上述行为，情节严重的，将提交公安机关处理。</p>
        <p>8、您不得发布任何侵犯他人著作权、商标权等知识产权或其他合法权利的内容；如果有其他用户或权利人发现您发布的信息涉嫌知识产权、或其他合法权益争议的，这些用户或权利人要求研习社删除您发布的信息，或者采取其他必要措施予以制止的，研习社将会依法采取这些措施。</p>
        <p>9、若您未遵守以上规定的，研习社有权做出独立判断并采取暂停或注销您的账号等措施。 </p>
    <h3>四、 研习社的权利和义务</h3>
        <p>1、研习社有义务在现有技术的基础上维护整个网站的正常运行，并努力提升和改进技术，使用户的活动得以顺利进行。</p>
        <p>2、用户理解并认同，因网上社区平台的特殊性，研习社没有义务对所有用户使用研习社的过程及行为进行事前及实时审查。但用户在研习社上有下列行为的，将授权研习社可以普通、非专业人员的知识水平标准或根据自己掌握的信息进行独立判断，并有权做出相应处理，而无须事先通知用户或取得用户同意：</p>
            <p style="\&quot;margin-left:2em;\&quot;">① 研习社有权对用户的注册信息进行查阅，如发现注册信息中存在任何问题的，有权向用户发出询问及要求改正或者做出冻结或关闭账户等处理；</p>
            <p style="\&quot;margin-left:2em;\&quot;">② 用户违反本协议或研习社任何规则或有违反法律法规和地方规章的行为的，研习社有权删除其信息，禁止用户发言，限制用户订购，注销用户账户并按照相关法律规定向相关国家主管部门进行披露；</p>
            <p style="\&quot;margin-left:2em;\&quot;">③ 如用户在研习社进行下列行为，研习社有权对用户采取删除其信息、禁止用户发言、冻结或注销用户账户等限制性措施：包括：</p>
            <p style="\&quot;margin-left:4em;\&quot;">a) 发布或以电子邮件或以其他方式传输存在恶意、虚假和侵犯他人人身财产权利内容的信息；</p>
            <p style="\&quot;margin-left:4em;\&quot;">b) 将有关干扰、破坏或限制任何计算机软件、硬件或通讯设备功能的软件病毒或其他计算机代码、档案和程序之资料，加以上载、发布、发送电子邮件或以其他方式传送；</p>
            <p style="\&quot;margin-left:4em;\&quot;">c) 干扰或破坏研习社和服务或与研习社和服务相连的服务器和网络；</p>
            <p style="\&quot;margin-left:4em;\&quot;">d) 发布其他违反公共利益或可能严重损害研习社和其他用户合法利益的信息。</p>
        <p>3、用户理解并同意，当用户涉嫌侵犯他人合法权益， 研习社有权在初步判断涉嫌侵权行为可能存在的情况下，在必要时可向权利人提供必要的已掌握的涉及用户的相关信息。</p>
        <p>4、用户理解并同意，当国家行政、司法机关主动依职权或根据他人的投诉，调查用户在研习社上的相关行为时，研习社有义务予以配合，并将所掌握的信息提供给上述国家机关。</p>
        <p>5、用户在此授予研习社无偿、永久的独家使用权，并有权对该使用权进行再授权，以使研习社有权在全球范围内全部或部分地使用、复制、修改、改写、发布、翻译和展示用户登记或发布在研习社上的各类信息，或制作其派生作品，和/或以现在已知或日后开发的任何形式、媒体或技术，将上述信息纳入研习社其他作品内。</p>
    <h3>五、 用户信息收集、使用与保护</h3>
        <p>1、您承诺以真实身份注册成为研习社的用户，并保证所提供的个人资料信息真实、准确、完整、合法有效，并在相关资料发生变更时及时更新注册信息，依据法律规定和本协议约定对所提供的信息承担相应的法律责任。同时，您保证研习社可以通过相关信息与您本人进行联系。研习社有权审查您注册所提供的相关信息是否真实、有效。若您所提供的资料信息与事实不符或所提供的资料信息已变更而未及时更新或有任何误导之嫌，导致研习社无法为您提供或进一步提供服务，研习社不因此承担任何责任，同时您须承担可能因此引起的相应责任及后果，并且研习社有权注销您的注册账户，终止您使用研习社提供的各类注册用户服务的权利。 </p>
        <p>2、您在研习社进行注册、浏览、发表评价、参加活动等行为时，涉及您的真实姓名/名称、通信地址、联系电话、电子邮箱、订单详情、评价或反馈、投诉内容、cookies等信息的，研习社有权客户服务、开展活动等多种角度予以收集，并将对其中涉及个人隐私信息予以严格保密。</p>
        研习社保证不对外公开或向任何第三方提供您的个人信息，但是存在下列情形之一的除外：<p></p>
            <p style="\&quot;margin-left:2em;\&quot;">① 事先获得您的明确授权；</p>
            <p style="\&quot;margin-left:2em;\&quot;">② 系为保护您的合法权利所必须；</p>
            <p style="\&quot;margin-left:2em;\&quot;">③ 系为履行法律义务；</p>
            <p style="\&quot;margin-left:2em;\&quot;">④ 本注册协议或其他条款另有约定。</p>
        <p>3、请阅读研习社的隐私声明以了解隐私政策。该声明适用于您访问研习社，或使用研习社提供的任何服务。</p>
        <p>4、Cookies的使用规则</p>
            <p style="\&quot;margin-left:2em;\&quot;">① 通过研习社所设Cookie所取得的有关信息，将适用本规则；</p>
            <p style="\&quot;margin-left:2em;\&quot;">② 在研习社上发布信息的第三方通过其所发布信息在用户计算机上设定Cookies的，将遵照该第三方的隐私权政策；</p>
            <p style="\&quot;margin-left:2em;\&quot;">③ 编辑和删除个人信息的权限：用户可以对用户的个人信息进行编辑和删除，除非研习社另有规定。</p>
        <p>5、您同意，研习社有权使用您的注册信息、用户名、密码等信息，登录进入您的注册账户，进行证据保全，包括但不限于公证、见证、协助司法机关进行调查取证等。 </p>
    <h3>六、 软件形式</h3>
        <p>若研习社依托“软件”向您提供服务，您还应遵守以下约定：</p>
        <p>1、研习社可能为不同的终端设备开发不同的软件版本，您应当根据实际需要选择下载合适的版本进行安装。</p>
        <p>2、如果您从未经合法授权的第三方获取本软件或与本软件名称相同的安装程序，研习社将无法保证该软件能否正常使用，并对因此给您造成的任何损失不予负责。</p>
        <p>3、为了增进用户体验、完善服务内容，研习社将不时提供软件更新服务(该更新可能会采取软件替换、修改、功能强化、版本升级等形式)。为了改善用户体验，保证服务的安全性和功能的一致性，研习社有权对软件进行更新或者对软件的部分功能效果进行改变或限制。</p>
        <p>4、软件新版本发布后，旧版软件可能无法使用。研习社不保证旧版软件继续可用，及相应的客户服务，请您随时核对并下载最新版本。</p>
        <p>5、 除非法律允许或研习社书面许可，您不得从事下列行为：</p>
            <p style="\&quot;margin-left:2em;\&quot;">① 删除软件及其副本上关于著作权的信息；</p>
            <p style="\&quot;margin-left:2em;\&quot;">② 对软件进行反向工程、反向汇编、反向编译或者以其他方式尝试发现软件的源代码；</p>
            <p style="\&quot;margin-left:2em;\&quot;">③ 对研习社拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；</p>
            <p style="\&quot;margin-left:2em;\&quot;">④ 对软件或者软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经合法授权的第三方工具/服务接入软件和相关系统；</p>
            <p style="\&quot;margin-left:2em;\&quot;">⑤ 修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论上述行为是否为商业目的；</p>
`,
};
export default applyList;
