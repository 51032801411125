
import { ElForm } from 'node_modules/element-ui/types/form';
import { Component, Vue } from 'vue-property-decorator';
import { useMessage } from '@/utils/message'; // 方便弹出吐司
import { verifyPhone, setCookie, setLocalAndSession } from '@/utils/utils';
import HeaderLogin from '@/components/HeaderLogin/index.vue';
import FooterLogin from '@/components/FooterLogin/index.vue';
import ImageVerify from "@/components/ImageVerify/index.vue";
import DragVerify from "@/components/DragVerify/index.vue";
import { encryptedData } from '@/utils/encrypt';
import { Base64 } from 'js-base64';
import { mime } from '@/utils/utils';

interface IFormData {
  username: string;
  password: any;
  smsCode: string;
}

@Component({
    name: 'login',
    components: {
        HeaderLogin,
        FooterLogin,
        ImageVerify,
        DragVerify,
    },
})

export default class Login extends Vue {
    private isWebkit: boolean = true; // 判断是否是webkit内核
    private isFirefox: boolean = false; // 判断是否是火狐浏览器
    private isChrome: boolean = false; // 判断是否是谷歌浏览器
    private psw: boolean = false; // 密码
    private dragVerifyFlag: boolean = false; // 滑动验证
    private smsType: string = '';
    private loading: boolean = false;
    private loginType: number = 1; // 1、密码登录；2、短线登录 test
    private openCodeFlag = false; // 开启图片验证码flag
    private imageCodeSrc = ''; // 图片验证码
    private imageCode: string = ''; // 接收输入的验证码
    private guid = ''; // 记录图片验证码 使用的uuid
    private redirectUrl = ''; // 记录返回的页面
    private mcToken: string = ''; // mc传过来的token，进行token登录
    // invitation start
    private invitation: string = '';
    private company: string = '';
    // end
    private timerData: any = {
        timerFlag: false,
        timerNum: 60,
        msg: '获取短信验证码',
    };
    private formData: IFormData = {
        username: '',
        password: '',
        smsCode: '',
    };
    private formRules: any = {
        username: [
            { required: true, message: '请输入手机号', trigger: 'change' },
            { validator: this.validateMobile, trigger: 'change' },
        ],
        password: [
            { required: false, validator: this.verifyPwd, trigger: 'change' },
        ],
        smsCode: [
            { required: false, validator: this.verifySmsCode, trigger: 'change' },
        ],
    };
    private mounted() {
        this.$nextTick(() => {
            const userAgent = navigator.userAgent; // 取得浏览器的userAgent字符串
            // isWebkit 如果是true的时候就密码使用 降级处理方式使用css来显示密码隐藏状态，谷歌为了点击手机密码自己自动填充做相应的判断调整
            if (userAgent.indexOf("Opera") > -1) { // 判断是否Opera浏览器
                this.isWebkit = true;
            } else if (userAgent.indexOf("Firefox") > -1) { // 判断是否Firefox浏览器
                this.isWebkit = false;
                this.isFirefox = true;
            } else if (userAgent.indexOf("Chrome") > -1 && (userAgent.indexOf('WOW64') > -1 || mime("type", "application/gameplugin"))) { // 360浏览器
                this.isWebkit = true;
            } else if (userAgent.indexOf("Chrome") > -1) { // 判断是否Chrome浏览器
                this.isWebkit = false;
                this.isChrome = true;
            } else if (userAgent.indexOf("Safari") > -1) { // 判断是否Safari浏览器
                this.isWebkit = true;
            } else {
                this.isWebkit = false;
            }
        });
    }
    private created() {
        console.log('process.env.VUE_APP_URL======>', process.env.VUE_APP_URL);
        window.addEventListener('popstate', () => {
            return history.go(0);
        });
        this.redirectUrl = '';
        if (this.$route.fullPath.indexOf('?redirect=') > -1) {
            this.redirectUrl = unescape(this.$route.fullPath.split('?redirect=')[1]);
        }
        if (this.$route.fullPath.indexOf('invitation=') > -1) {
            this.invitation = unescape(this.$route.query.invitation + '');
        }
        // 邀请带的公司名称 base64会把加号变成空格需要转换一下。现在的方案是先编码一次再base64，所以这里可有可无
        if (this.$route.fullPath.indexOf('company=') > -1) {
            const companyUrl = unescape(this.$route.query.company + '');
            this.company = Base64.decode(companyUrl.replace(/%20/g, '+').replace(/ /g, '+'));
        }
        if (this.$route.fullPath.indexOf('from=mc') > -1) {
            this.mcToken = unescape(this.$route.query.token + '');
            if (this.mcToken && this.mcToken !== '') {
                this.tokenLogin();
            }
        }
    }
    private validateMobile(rule: any, value: string, callback: any) {
        if (value === '') {
            callback(new Error('请输入手机号'));
        } else if (!verifyPhone(value)) {
            callback(new Error('手机号格式不正确!'));
        } else {
            callback();
        }
    }
    private verifySmsCode(rule: any, value: string, callback: any) {
        if (value === '' && this.loginType === 2) {
            callback(new Error('请输入验证码'));
        } else {
            callback();
        }
    }
    private verifyPwd(rule: any, value: string, callback: any) {
        if (value === '' && this.loginType === 1) {
            callback(new Error('请输入密码'));
        } else if (value !== '' && (value.length < 6 || value.length > 16)) {
            callback(new Error('密码长度在6~16位'));
        } else {
            callback();
        }
    }
    private enterSearch() {
        let throttleTimer: any = null;
        document.onkeydown = (e) => {
            const key = e.keyCode;
            if (!this.openCodeFlag && key === 13 && (e.target as any).baseURI.match(/login/)) {
                if (!throttleTimer) {
                    throttleTimer = setTimeout( () => {
                        this.submitForm('refForm');
                        throttleTimer = null;
                    }, 1000);
                }
            }
        };
    }
    private checkoutType(type: number) {
        if (type !== this.loginType) {
            this.psw = false;
            this.formData = {
                username: '',
                password: '',
                smsCode: '',
            };
            if (this.isChrome) {
                this.resetForm('refForm');
            }
            this.$nextTick(() => {
                const elForm: any = this.$refs.refForm;
                elForm.clearValidate();
                this.loginType = type;
            });
        }
    }
    private submitForm(formName: any) {
        if (this.openCodeFlag) {
            return;
        }
        const elForm = this.$refs[formName]! as ElForm;
        elForm.validate((valid: boolean) => {
            if (valid) {
                if (this.formData.password.length !== this.formData.password.trim().length) {
                    this.$message.error('密码格式错误，不能有空格~');
                    return false;
                }
                if (this.loginType === 1) {
                    this.smsType = '';
                    this.dragVerifyFlag = true;
                } else {
                    this.loginHandle();
                }
            } else {
                return false;
            }
        });
    }
    private loginHandle() {
        // 清除各种缓存数据，登录之前先把缓存全部清空一次
        localStorage.clear();
        this.loginType === 1 ? this.pwdLogin() : this.smsLogin();
    }
    private pwdLogin() {
        const paramData: any = {};
        paramData.username = this.formData.username;
        paramData.password = encryptedData(this.formData.password);
        paramData.inviteCode = this.invitation;
        this.loading = true;
        this.$httpService.postData(paramData, this.$apiList.login).then((res: any) => {
            this.handleLoginSuccess();
        }).catch(() => {
            this.loading = false;
        });
    }
    private smsLogin() {
        const paramData: any = {};
        paramData.mobile = this.formData.username;
        paramData.smsCode = this.formData.smsCode;
        paramData.inviteCode = this.invitation;
        this.loading = true;
        this.$httpService.postData(paramData, this.$apiList.smsLogin).then((res: any) => {
            this.handleLoginSuccess();
        }).catch(() => {
            this.loading = false;
        });
    }
    private tokenLogin() {
        const paramData: any = {};
        paramData.token = this.mcToken;
        this.loading = true;
        this.$httpService.postData(paramData, '/apiProxy/api/frontend/passport/mc/login').then((res: any) => {
            this.handleLoginSuccess();
        }).catch(() => {
            this.loading = false;
        });
    }
    private handleLoginSuccess() {
        // 获取当前用户信息
        this.loading = true;
        this.$httpService.getData({type: 'getUserType'}, this.$apiList.userInfo)
        .then((resCurrent: any) => {
            if (this.redirectUrl !== '') {
                setLocalAndSession(resCurrent);
                if (this.redirectUrl.indexOf('redirect=1') > -1) {
                    window.location.href = this.redirectUrl;
                } else {
                    if (this.redirectUrl.indexOf('?') > -1) {
                        window.location.href = this.redirectUrl + '&redirect=1';
                    } else {
                        window.location.href = this.redirectUrl + '?redirect=1';
                    }
                }
                return;
            }
            useMessage(1, '登录成功');
            if (resCurrent.email && resCurrent.email !== '') {
                localStorage.setItem("userEmail", JSON.stringify(resCurrent.email));
            }
            if (resCurrent.userType === 1) {
                this.$router.push("/person").catch((err) => err);
            } else {
                this.$router.push("/company").catch((err) => err);
            }
        }).finally(() => {
            this.loading = false;
        });
    }
    private resetForm(formName: any) {
        const elForm = this.$refs[formName]! as ElForm;
        elForm.resetFields();
    }
    private handleAccount(action: string) {
        if (action === 'register') {
            if (this.invitation !== '' && this.company !== '') {
                this.$router
                .push(`/register?invitation=${encodeURIComponent(this.invitation)}&company=${encodeURIComponent(Base64.encode(this.company))}`)
                .catch((err) => err);
            } else {
                this.$router.push("/register").catch((err) => err);
            }
        } else if (action === 'forget') {
            this.$router.push("/findPwd").catch((err) => err);
        }
    }
    // 接收关闭弹窗传回来的值
    private propCloseImageCode(val: any) {
        this.openCodeFlag = false;
        if (val[1] !== '') {
            this.startTimer();
        }
    }
    private sendCode() {
        if (this.formData.username === '') {
            const refForm = this.$refs.refForm! as ElForm;
            refForm.validateField('username');
        } else if (!verifyPhone(this.formData.username)) {
            const refForm = this.$refs.refForm! as ElForm;
            refForm.validateField('username');
        } else {
            if (this.timerData.timerFlag) {
                return;
            }
            // this.openCodeFlag = true;
            this.smsType = 'login';
            this.dragVerifyFlag = true;
        }
    }
    private startTimer() {
        this.timerData.timerFlag = true;
        this.timerData.msg = this.timerData.timerNum + 's后重发';
        const myInterval = setInterval( () => {
            this.timerData.timerNum--;
            this.timerData.msg = this.timerData.timerNum + 's后重发';
            if (this.timerData.timerNum <= 0) {
              clearInterval(myInterval);
              this.timerData = {
                  timerFlag: false,
                  timerNum: 60,
                  msg: '获取短信验证码',
              };
            }
        }, 1000);
    }
    private propDragVerifyCloseMsg(num: number) {
        this.dragVerifyFlag = false;
        if (num === 1) {
            if (this.loginType === 1) {
                this.loginHandle();
            } else {
                this.startTimer();
            }
        }
    }
}
