
import { Component, Vue } from 'vue-property-decorator';
@Component({
  name: 'HeaderLogin',
})
export default class HeaderLogin extends Vue {
    // 跳转
    private navLink(type: number) {
        if (type === 1) {
            this.$router.push('/index');
        } else if (type === 2) {
            window.open('https://www.morewis.com/');
        } else if (type === 3) {
            window.open('https://ntalk.morewis.com/web/home');
        }
    }
}
