
import { Component, Vue } from 'vue-property-decorator';
@Component({
  name: 'FooterLogin',
})
export default class extends Vue {
    // 跳转icp
    private openICP() {
        window.open('https://beian.miit.gov.cn/');
    }
}
