
import { Component, Vue } from 'vue-property-decorator';
import { useMessage } from '@/utils/message'; // 方便弹出吐司
import { ElForm } from 'element-ui/types/form';
import HeaderLogin from '@/components/HeaderLogin/index.vue';
import FooterLogin from '@/components/FooterLogin/index.vue';
import { encryptedData } from '@/utils/encrypt';

@Component({
    name: 'resetPwd',
    components: {
        HeaderLogin,
        FooterLogin,
    },
})
export default class ResetPwd extends Vue {
    private isWebkit: boolean = true; // 判断是否是webkit内核
    private psw: boolean = false; // 密码
    private confirmPsw: boolean = false; // 确认密码
    private resetData: any = {
        mobile: '',
        password: '',
        confirmPwd: '',
    };
    private formRules: any = {
        password: [
            { required: true, message: '请输入密码', trigger: 'change' },
            { validator: this.validatePwd, trigger: 'blur' },
        ],
        confirmPwd: [
            { required: true, message: '请输入确认密码', trigger: 'change' },
            { validator: this.validateConfirmPwd, trigger: 'blur' },
        ],
    };
    private validatePwd(rule: any, value: string, callback: any) {
        if (value === '') {
            callback(new Error('请输入密码'));
        } else if (value !== '' && (value.length < 6 || value.length > 16)) {
            callback(new Error('密码长度在6~16位'));
        } else {
            callback();
        }
    }
    private validateConfirmPwd(rule: any, value: string, callback: any) {
        if (value === '') {
            callback(new Error('请输入确认密码'));
        } else if (value !== '' && (value.length < 6 || value.length > 16)) {
            callback(new Error('密码长度在6~16位'));
        } else if (value !== this.resetData.password) {
            callback(new Error('两次输入密码不一致!'));
        } else {
            callback();
        }
    }
    private mounted() {
        this.$nextTick(() => {
            const userAgent = navigator.userAgent; // 取得浏览器的userAgent字符串
            if (userAgent.indexOf("Opera") > -1) { // 判断是否Opera浏览器
                this.isWebkit = true;
            } else if (userAgent.indexOf("Firefox") > -1) { // 判断是否Firefox浏览器
                this.isWebkit = false;
            } else if (userAgent.indexOf("Chrome") > -1) { // 判断是否Chrome浏览器
                this.isWebkit = true;
            } else if (userAgent.indexOf("Safari") > -1) { // 判断是否Safari浏览器
                this.isWebkit = true;
            } else {
                this.isWebkit = false;
            }
        });
    }
    private created() {
        this.resetData.mobile = this.$route.params.mobile;
    }
    private nextSubmit(formName: any) {
        const elForm = this.$refs[formName]! as ElForm;
        elForm.validate((valid: boolean) => {
            if (valid) {
                const paramData: any = {};
                paramData.mobile = this.resetData.mobile;
                paramData.password = encryptedData(this.resetData.password);
                this.$httpService.postData(paramData, "/apiProxy/api/frontend/passport/retrieve/commit")
                .then((res: any) => {
                    useMessage(1, '修改成功');
                    setTimeout(() => {
                        this.$router.push("/login").catch((err) => err);
                    }, 1000);
                });
            } else {
                useMessage(3, '输入的信息有误~');
                return false;
            }
        });
    }
}
