
import { Component, Vue } from 'vue-property-decorator';
import { useMessage } from '@/utils/message'; // 方便弹出吐司
import { verifyPhone } from '@/utils/utils';
import { ElForm } from 'element-ui/types/form';
import HeaderLogin from '@/components/HeaderLogin/index.vue';
import FooterLogin from '@/components/FooterLogin/index.vue';
import ImageVerify from "@/components/ImageVerify/index.vue";
import DragVerify from "@/components/DragVerify/index.vue";

@Component({
    name: 'findPwd',
    components: {
        HeaderLogin,
        FooterLogin,
        ImageVerify,
        DragVerify,
    },
})
export default class FindPwd extends Vue {
    private dragVerifyFlag: boolean = false; // 滑动验证
    private openCodeFlag = false; // 开启图片验证码flag
    private imageCodeSrc = ''; // 图片验证码
    private imageCode: string = ''; // 接收输入的验证码
    private guid = ''; // 记录图片验证码 使用的uuid
    private findData: any = {
        userType: 1,
        mobile: '',
        smsCode: '',
    };
    private timerData: any = {
        timerFlag: false,
        timerNum: 60,
        msg: '获取短信验证码',
    };
    private formRules: any = {
        mobile: [
            { required: true, message: '请输入手机号', trigger: 'change' },
            { validator: this.validateMobile, trigger: 'blur' },
        ],
        smsCode: [
            { required: true, message: '请输入验证码', trigger: 'change' },
        ],
    };
    private validateMobile(rule: any, value: string, callback: any) {
        if (value === '') {
            callback(new Error('请输入手机号'));
        } else if (!verifyPhone(value)) {
            callback(new Error('手机号格式不正确!'));
        } else {
            callback();
        }
    }
    private switchUserType(uType: number) {
        if (this.findData.userType === uType) {
            return;
        }
        this.resetForm('findRef');
        this.findData.userType = uType;
    }
    private resetForm(formName: any) {
        const elForm = this.$refs[formName]! as ElForm;
        elForm.resetFields();
    }
    private sendCode() {
        if (this.findData.mobile === '') {
            const findRef = this.$refs.findRef! as ElForm;
            findRef.validateField('mobile');
        } else if (!verifyPhone(this.findData.mobile)) {
            const findRef = this.$refs.findRef! as ElForm;
            findRef.validateField('mobile');
        } else {
            if (this.timerData.timerFlag) {
                return;
            }
            this.dragVerifyFlag = true;
        }
    }
    // 接收关闭弹窗传回来的值
    private propCloseImageCode(val: any) {
        this.openCodeFlag = false;
        if (val[1] !== '') {
            this.startTimer();
        }
    }
    private sendRegisterSMS() {
        const paramData: any = {};
        paramData.captcha = this.imageCode;
        paramData.captchaUUID = this.guid;
        paramData.mobile = this.findData.mobile;
        paramData.userType = this.findData.userType;
        this.$httpService.postData(paramData, "/apiProxy/api/frontend/passport/retrieve/sms/captcha")
        .then((res: any) => {
            this.findData.smsCode = res.smsCode || '';
            this.startTimer();
        });
    }
    private startTimer() {
        this.timerData.timerFlag = true;
        const myInterval = setInterval( () => {
            this.timerData.timerNum--;
            this.timerData.msg = this.timerData.timerNum + 's后重发';
            if (this.timerData.timerNum <= 0) {
              clearInterval(myInterval);
              this.timerData = {
                  timerFlag: false,
                  timerNum: 60,
                  msg: '获取短信验证码',
              };
            }
        }, 1000);
    }
    private nextSubmit(formName: any) {
        const elForm = this.$refs[formName]! as ElForm;
        elForm.validate((valid: boolean) => {
            if (valid) {
                const paramData: any = {};
                paramData.smsCode = this.findData.smsCode;
                paramData.mobile = this.findData.mobile;
                paramData.userType = this.findData.userType;
                this.$httpService.postData(paramData, "/apiProxy/api/frontend/passport/retrieve/prepare")
                .then((res: any) => {
                    // this.$router.push("/resetPwd").catch((err) => err);
                    this.$router.push({
                        name: "ResetPwd",
                        params: {
                            userType: this.findData.userType,
                            mobile: this.findData.mobile,
                            morewisNumber: res.morewisNumber,
                        },
                        }).catch((err) => err);
                });
            } else {
                useMessage(3, '输入的信息有误~');
                return false;
            }
        });
    }
    private gotoLogin() {
        this.$router.push("/login").catch((err) => err);
    }
    private propDragVerifyCloseMsg(num: number) {
        this.dragVerifyFlag = false;
        if (num === 1) {
            this.startTimer();
        }
    }
}
